import request from '@/utils/request'
import constant from '@/constant'

function list(formData){
  return request.post(constant.serverUrl + "/business/cameraLiveRadio/list", formData);
}

function schoolList(){
  return request.post(constant.serverUrl + "/business/cameraLiveRadio/schoolList");
}

function classList(formData){
  return request.post(constant.serverUrl + "/business/cameraLiveRadio/classList",formData);
}

function edit(id){
  return request.get(constant.serverUrl + "/business/cameraLiveRadio/edit/" + id);
}


function update(formModel){
  return request.post(constant.serverUrl + "/business/cameraLiveRadio/update", formModel,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function exportXls(formData) {
  //导出xls
  return request.post(constant.serverUrl + "/business/cameraLiveRadio/exportXls", formData);
}


export default {
  list,schoolList,classList,edit,update,exportXls
}