<template>
    <el-dialog
        :visible.sync="showDialog"
        width="40%"
        :title="title"
        :modal-append-to-body="false"
        style="text-align:left;"
        @close="closeDialog"
        :close-on-click-modal="false"
    >
    <div class="user-panel" v-loading="loading">
        <el-form ref="form" :model="formModel" :rules="ruleValidate" :label-width="'100px'">
            <el-form-item label="选择学校" prop="schoolId">
                <el-select v-model="formModel.schoolId" filterable  placeholder="请选择" >
                    <el-option
                    v-for="item in schoolList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="有效期" prop="termOfValidity">
                <el-date-picker
                v-model="formModel.termOfValidity"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
                ></el-date-picker>
            </el-form-item>

            <el-form-item label="菜谱图片" prop="photo">
                <el-upload
                class="avatar-uploader"
                name="photoFile"
                :action="uploadUrl"
                :show-file-list="false"
                :headers="headers"
                :data="uploadData"
                :on-success="handleAvatarSuccess"
                >
                <img v-if="fileUrl" :src="fileUrl" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                <div slot="tip" class="el-upload__tip">只能上传jpg/png图片，且不超过5MB</div>
                </el-upload>
                
            </el-form-item>
        </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button type="primary" @click="handleSubmit"  :loading="submitting">确 定</el-button>
    </span>
    </el-dialog>
</template>
<script>
import Constant from "@/constant";
import canteenMenuApi from "@/api/base/canteenMenu";
import cameraLiveRadioApi from '@/api/business/cameraLiveRadioApi'
import { getToken } from "@/utils/auth"; // get token from cookie
export default {
    props: ["businessKey", "title"],
    data() {
        return{
            formModel:{
                id: "",
                termOfValidity:'',
                schoolId:"",
            },
            ruleValidate: {
                schoolId: [{ required: true, message: "请选择学校", trigger: "blur" }],
                termOfValidity: [{ required: true, message: "有效期不能为空", trigger: "blur" }],
            },
            //上传地址
            uploadUrl: Constant.serverUrl + "/uploadPicture",
            headers: {
              Authorization: getToken()
            },
            uploadData: {
              subFolder: "canteenMenu"
            },
            fileUrl: "",
            showDialog: true,
            loading: false,
            submitting: false,
            schoolList:[],
        }
    },
    methods: {
        closeDialog() {
            this.$emit("close", false);
        },
        handleSubmit() {
            var self = this;
            this.$refs["form"].validate(valid => {
                if (valid) {
                (function() {
                    var id = self.formModel.id;

                    
                    if(self.formModel.termOfValidity!=null&&self.formModel.termOfValidity!=""){
                        var termOfValidity = self.formModel.termOfValidity.toString();
                        self.formModel.termOfValidity = termOfValidity;
                    }

                    if (id == null || id.length == 0) {
                    return canteenMenuApi.add(self.formModel);
                    } else {
                    return canteenMenuApi.update(self.formModel);
                    }    
                })().then(function(response) {
                    var jsonData = response.data;

                    if (jsonData.result) {
                    self.$message({
                        message: "保存成功!",
                        type: "success"
                    });
                    self.$emit("close", {
                        result: true,
                        data: jsonData.data
                    });
                    } else {
                    self.$message({
                        message: jsonData.message + "",
                        type: "warning"
                    });

                    self.$emit("close", {
                        result: false
                    });
                    }
                });
                }
            });
        },
        handleAvatarSuccess(res, file) {
            var self = this;
            self.formModel.photo = res.data;
            self.fileUrl =
                res.data + "?x-oss-process=image/resize,m_lfit,h_400,w_400";
        },
        beforeAvatarUpload(file) {
            const isJPG = file.type === 'image/jpeg';
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
            this.$message.error('上传头像图片只能是 JPG 格式!');
            }
            if (!isLt2M) {
            this.$message.error('上传头像图片大小不能超过 2MB!');
            }
            return isJPG && isLt2M;
        }
    },
    created() {
        var self = this;

        cameraLiveRadioApi.schoolList().then(response => {
            var jsonData = response.data;
            this.schoolList = jsonData.data;
        });
    },
    async mounted() {
        console.log("mounted");

        var self = this;

        (function() {
            if (self.businessKey.length == 0) {
                return canteenMenuApi.create();
            } else {
                return canteenMenuApi.edit(self.businessKey);
            }
        })()
        .then(response => {
            var jsonData = response.data;

            if (jsonData.result) {
                self.formModel = jsonData.data;
                if(jsonData.data.id!=null&&jsonData.data!=""){
                    var termOfValidity = jsonData.data.termOfValidity;

                    if(termOfValidity!=null&&termOfValidity!=""){
                        var rangeTime = termOfValidity.split(",")

                        this.formModel.termOfValidity = rangeTime;
                    }
                    let photo = self.formModel.photo;
                    if (photo != null) {
                    self.fileUrl =
                        photo + "?x-oss-process=image/resize,m_lfit,h_400,w_400";
                    }
                }

            } else {
            self.$message.error(jsonData.message + "");
            }
        })
        .catch(error => {
            self.$message.error(error + "");
        });
    }
}
</script>
<style scoped>
.user-panel {
  margin: 10px auto;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
  object-fit: cover;
}
</style>