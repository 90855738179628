<template>
    <div class="canteenMenu-list">
        <el-breadcrumb separator=">">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>
                <a href="#">菜谱管理</a>
            </el-breadcrumb-item>
        </el-breadcrumb>
        <el-divider></el-divider>
        <el-form ref="queryForm" :model="queryModel" inline class="demo-form-inline">
            <el-form-item label="选择学校" prop="schoolId">
                <el-select v-model="queryModel.schoolId" filterable  size="mini" placeholder="请选择" >
                    <el-option
                    v-for="item in schoolList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button
                type="primary"
                size="mini"
                icon="ios-search"
                @click="changePage(1)"
                :loading="loading"
                >查询</el-button>&nbsp;
                <el-button
                    type="info"
                    size="mini"
                    style="margin-left: 8px"
                    @click="handleReset('queryForm')"
                >重置</el-button>
            </el-form-item>
        </el-form>
        <el-divider></el-divider>
        <el-row class="button-group">
        <el-button type="primary" size="small" plain icon="el-icon-circle-plus" @click="handleAdd">新增</el-button>
        <el-button
            type="primary"
            size="small"
            plain
            icon="el-icon-circle-plus"
            :disabled="multipleSelection.length==0"
            @click="handleBatchDelete"
        >删除选中项</el-button>
        </el-row>
                <el-table
            ref="formTable"
            :data="tableData"
            v-loading="loading"
            element-loading-text="拼命加载中"
            stripe
            @selection-change="handleSelectionChange"
        >
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column prop="schoolName" label="学校名称"></el-table-column>
            <el-table-column prop="termOfValidity" label="有效期"></el-table-column>
            <el-table-column prop="photo" label="菜谱照片" >
                <template slot-scope="{row}">
                <a v-if="row.photo" :href="row.photo" target="_blank">
                    <el-image
                    :size="50"
                    :src="row.photo+'?x-oss-process=image/resize,m_fill,w_64,h_64'"
                    :key="row.id"
                    ></el-image>
                </a>
                </template>
            </el-table-column>
            <el-table-column prop="createTime" label="创建时间"></el-table-column>
            <el-table-column label="操作" width="150" fixed="right">
                <template slot-scope="{row}">
                    <el-button size="mini" type="warning" @click="handleEdit(row)">编辑</el-button>
                    <el-button size="mini" type="danger" @click="handleDelete(row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <div style="text-align:center;">
            <el-pagination
                :current-page.sync="pageIndex"
                :total="totalElements"
                :page-sizes="pageSizeList"
                @current-change="changePage"
                @size-change="pageSizeChange"
                layout="total, sizes, prev, pager, next,  jumper"
            ></el-pagination>
        </div>
        <canteenMenu-detail
            v-if="showModal"
            :businessKey="businessKey"
            :parentId="selectedRecord.id"
            :title="modalTitle"
            @close="onDetailModalClose"
        ></canteenMenu-detail>
    </div>
</template>
<script>
import canteenMenuApi from "@/api/base/canteenMenu";
import cameraLiveRadioApi from '@/api/business/cameraLiveRadioApi'
import canteenMenuDetail from "./canteenMenu-detail";
export default {
    name: "baseCanteenMenuList",
    data(){
        return{
            queryModel:{
                schoolId:"",
            },
            loading: false,
            tableData: [],
            multipleSelection: [],
            pageIndex: 1,
            pageSize: 10,
            totalElements: 0,
            pageSizeList: [10,20,30],
            schoolList:[],
            showModal: false,
        }
    },
    methods: {
        changePage(pageIndex) {
            this.loading = true;
            var formData = new FormData();

            formData.append("pageIndex", this.pageIndex);
            formData.append("pageSize", this.pageSize);
            formData.append("schoolId", this.queryModel.schoolId);


            canteenMenuApi.pageList(formData).then(response => {
                this.loading = false;
                var jsonData = response.data;

                console.log(jsonData);

                this.tableData = jsonData.data.data;
                this.totalElements = jsonData.data.recordsTotal;

                this.pageIndex = pageIndex;
            });
        },
        pageSizeChange(pageSize) {
            this.pageSize = pageSize;
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        handleReset(name) {
            this.$refs[name].resetFields();
        },
        handleAdd(record) {
            this.modalTitle = "新增";
            this.operation = "add";
            this.businessKey = "";

            if (record != null) {
                this.selectedRecord = record;
            } else {
                this.selectedRecord = {};
            }

            this.showModal = true;
        },
        handleEdit(record) {
            this.modalTitle = "编辑";
            this.operation = "edit";
            this.businessKey = record.id;
            this.selectedRecord = record;
            this.showModal = true;
        },
        loopDelete(list, id) {
            var rs = false;

            for (var i = 0; i < list.length; i++) {
                if (list[i].id == id) {
                    list.splice(i, 1);
                    rs = true;
                    break;
                }

                if (list[i].children != null) {
                    rs = this.loopDelete(list[i].children, id);

                    if (rs) {
                        break;
                    }
                }
            }

            return rs;
        },
        handleDelete(record) {
            var self = this;

            self
                .$confirm("是否确认删除?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                })
                .then(() => {
                    canteenMenuApi.remove(record.id).then(function (response) {
                        var jsonData = response.data;

                        if (jsonData.result) {
                            var rs = self.loopDelete(self.tableData, record.id);

                            self.$message({
                                type: "success",
                                message: "删除成功!"
                            });
                        }
                    });
                });
        },
        handleBatchDelete() {
                var self = this;

                var idList = this.multipleSelection.map(record => {
                    return record.id;
                });

                this.$confirm("是否确认删除选中项？", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(() => {
                    canteenMenuApi.batchRemove(idList).then(function (response) {
                        var jsonData = response.data;

                        if (jsonData.result) {
                            self.changePage(self.pageIndex);

                            self.$message({
                                type: "success",
                                message: "删除成功!"
                            });
                        }
                    });
                });
            },
        onDetailModalClose(refreshed) {
            //保存成功后回调
            this.showModal = false;

            if (refreshed) {
                this.changePage(this.pageIndex);
            }
        },
    },
    created() {
        var self = this;

        cameraLiveRadioApi.schoolList().then(response => {
            var jsonData = response.data;
            this.schoolList = jsonData.data;
        });
    },
    mounted: function () {
        this.changePage(1);
    },
    components: {
        "canteenMenu-detail": canteenMenuDetail
    },
}
</script>
<style scoped>
.el-breadcrumb {
    margin: 10px;
    line-height: 20px;
}

.el-divider {
    margin: 5px 0;
}

.canteenMenu-list {
    text-align: left;
}
</style>