import request from '@/utils/request'
import constant from '@/constant'

function pageList(formData){
    return request.post(constant.serverUrl + "/base/canteenMenu/pageList", formData);
  }

  function create() {
    return request.get(constant.serverUrl + "/base/canteenMenu/create");
  }
  
  function edit(id) {
    return request.get(constant.serverUrl + "/base/canteenMenu/edit/" + id);
  }
  
  function add(formModel) {
    return request.post(constant.serverUrl + "/base/canteenMenu/add", formModel, {
      headers: {
        "Content-Type": "application/json"
      }
    });
  }

  function remove(id){
    return request.post(constant.serverUrl + "/base/canteenMenu/delete/" + id);
  }
  
  function batchRemove(idList){
    return request.post(constant.serverUrl + "/base/canteenMenu/batchDelete",idList,{
      headers: {
        "Content-Type": "application/json"
      }
    });
  }
  
  function update(formModel) {
    return request.post(constant.serverUrl + "/base/canteenMenu/update", formModel, {
      headers: {
        "Content-Type": "application/json"
      }
    });
  }



export default {
    pageList,create,edit,add,update,remove,batchRemove
}
  